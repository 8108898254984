const Verification = ({setScreen,next}) => {
  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">Promote Sale</h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">On your website or social media</h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Launch a digital gifting solution on your website without
                <span>
                  complex integrations, Copy and paste Prime widget URL
                </span>
                on your website to promote sales
              </h4>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Share your gift card on your social media platforms to
                <span>increase sales</span>
              </h4>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <a href="login.html" className="btn btn-outline-success">
                        Log In
                      </a>
                    </h4>
                  </div>
                  <div className="mb-4">
                      <img
                        src="assets/img/arrow-left.png"
                        alt=""
                        className="arrow-margin-top btn-prev"
                        onClick={()=>setScreen('credentials')}
                      />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Verify Account</h3>
                  </div>
                  <div>
                    <h4>Enter your details here</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      Last step, <span>Enter the six digit code sent you</span>
                    </h4>
                  </div>
                  <div className="form-group mt-4">
                    <input
                      type="number"
                      className="form-control textbx-size1"
                      placeholder="Six digit code"
                    />
                  </div>
                  <div className="mt-4 continue-btn">
                    <button onClick={()=>setScreen(next)} className="btn btn-success btn-next">Verify</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
