import { useState } from "react";

const AccountManager = ({ setScreen, next, userInput, handleUserInput }) => {
  const [errors, setError] = useState({
    fullname: "",
    position: "",
  });

  const handleOnChange = (e) => {
    setError((errors) => ({
      ...errors,
      [e.target.name]: "",
    }));
    handleUserInput(e);
  };

  const handleValidation=(e)=>{
    e.preventDefault();
    if (userInput.fullname === "" || !userInput.fullname ) {
      setError((errors) => ({
        ...errors,
        fullname: "Please Enter your name",
      }));
    }
    if (userInput.position === "" || !userInput.position ) {
      setError((errors) => ({
        ...errors,
        position: "Please Enter your positon",
      }));
    } else {
      setScreen(next);
    }
  }

  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">Prime Pay App</h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">Made Simple!</h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Download the Prime Pay app to quickly scan & redeem gift
                <span>cards with the QR Code Scanner</span>
                See how your business is doing on-the-go.
                <span>Access to all of your sales activity</span>
              </h4>
            </div>
            <div className="mt-5">
              <a href="/signup" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <a href="login.html" className="btn btn-outline-success">
                        Log In
                      </a>
                    </h4>
                  </div>
                  <div className="mb-4">
                    <img
                      src="assets/img/arrow-left.png"
                      alt=""
                      className="arrow-margin-top btn-prev"
                      onClick={() => setScreen("businessDescription")}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Account Manager</h3>
                  </div>
                  <div>
                    <h4>Enter your details below</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      Hi, <span>Just want to know you</span>
                    </h4>
                  </div>
                  <div className="form-group mt-4">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter your full name here"
                      name="fullname"
                      value={userInput.fullname}
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.fullname}</div>
                  </div>
                  <div className="form-group mt-4">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter your position here"
                      value={userInput.position}
                      name="position"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.position}</div>
                  </div>
                  <div class="form-group form-position">
															
															<select className="form-control textbx-size1 mt-4" id="sel2" name="gender" onChange={handleOnChange} value={userInput.gender}>
																<option value="">Gender</option>
																<option value="male">Male</option>
																<option value="female">Female</option>
															</select>
														</div>
                  <div className="mt-4 continue-btn">
                    <button
                      className="btn btn-success btn-next"
                      onClick={handleValidation}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountManager;
