import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Sidebar from "../components/Sidebar";


const DashboardLayout = (props) => {
  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <PageHeader />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
