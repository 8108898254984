import { useState } from "react";

const BusinessDetails = ({ setScreen, next, userInput, handleUserInput }) => {
  const [errors, setError] = useState({
    business_name: "",location:"",tin_number:"",telephone:"",email:""
  });

  const handleOnChange=(e)=>{
    setError((errors)=>({
      ...errors,[e.target.name]:""
    }))
    handleUserInput(e)
  }

  const handleValidation = () => {
    if (userInput.business_name === "" || !userInput.business_name) {
      setError((errors) => ({
        ...errors,
        business_name: "Please Enter Business Name",
      }));
    } else if (userInput.location === "" || !userInput.location) {
      setError((errors) => ({
        ...errors,
        location: "Please Enter Business Location",
      }));
    }else if (userInput.tin_number === "" || !userInput.tin_number) {
      setError((errors) => ({
        ...errors,
        tin_number: "Please Enter Tin Number",
      }));
    }else if (userInput.telephone === "" || !userInput.telephone) {
      setError((errors) => ({
        ...errors,
        telephone: "Please Enter Phone Number",
      }));
    }else if (userInput.email === "" || !userInput.email ) {
      setError((errors) => ({
        ...errors,
        email: "Please Enter a valid Email Address",
      }));
    } else {
      setScreen(next);
    }
  };
  
  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-content padding-div-top">
            <h1 className="text-white">
              Go digital with your <span>company branded</span>e-gift card
            </h1>
          </div>
          <div className="left-div-cont-last">
            <h4 className="text-white">
              Branded E-Gift cards are more than
              <span>a great gift idea. They're powerful</span>
              business tools that can help your{" "}
              <span>company grow and increase your</span>
              audience's engagement with your <span>brand.</span>
            </h4>
          </div>
          <div className="mt-5">
            <a href="/signup" className="btn btn-warning learn-more-btn">
              Learn more
            </a>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex float-right log-size">
                    <h4>
                      Already registered?
                      <a href="login.html" className="btn btn-outline-success">
                        Log In
                      </a>
                    </h4>
                  </div>
                  <div className="mb-4">
                      <img
                        src="assets/img/arrow-left.png"
                        alt=""
                        className="arrow-margin-top btn-prev"
                        onClick={() => setScreen("affiliate")}
                      />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h3>Sign up to Prime Merchant</h3>
                  </div>
                  <div>
                    <h4>Enter your details below</h4>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      Hello, <span>What's your business name?</span>
                    </h4>
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter your business name here"
                      value={userInput.business_name}
                      name="business_name"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.business_name}</div>
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter your location here"
                      value={userInput.location}
                      name="location"
                      onChange={handleOnChange}
                    />
                    <img
                      src="assets/img/location.png"
                      alt=""
                      className="img-fluid loc-img-pos"
                    />
                    <div className="text-danger">{errors.location}</div>
                  </div>
                  <div className="form-group mt-2">
                    <input
                      type="text"
                      className="form-control textbx-size1"
                      placeholder="Enter your business Tin Number"
                      value={userInput.tin_number}
                      name="tin_number"
                      onChange={handleOnChange}
                    />
                    <div className="text-danger">{errors.tin_number}</div>
                  </div>
                  <div className="form-group1 mt-2 d-flex justify-content-between">
                    <div className="d-block">
                      <input
                        type="text"
                        className="form-control textbx-size2"
                        placeholder="Enter your telephone here"
                        value={userInput.telephone}
                        name="telephone"
                        onChange={handleOnChange}
                      />
                      <div className="text-danger">{errors.telephone}</div>
                    </div>
                    <div className="d-block">
                      <input
                        type="text"
                        className="form-control textbx-size2"
                        placeholder="Enter your email here"
                        value={userInput.email}
                        name="email"
                        onChange={handleOnChange}
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div>
                  </div>
                  <div className="font-enter1">
                    <h4>
                      Where do you want to receive your verification code?
                    </h4>
                  </div>
                  {/* Come Back to this */}
                  <div className="radio-group1 d-flex">
                    <label
                      htmlFor="myRadioId2"
                      className="radio1 d-flex align-items-center"
                    >
                      <input
                        type="radio"
                        name="verification_code_destination"
                        value="phone"
                        onChange={handleUserInput}
                        id="myRadioId2"
                        className="radio__input mr-1"
                        defaultChecked
                      />
                      <div className="radio__radio1"></div>
                      <h4 className="my-auto">Phone</h4>
                    </label>

                    <label
                      htmlFor="myRadioId3"
                      className="radio1 d-flex align-items-center ml-4"
                    >
                      <input
                        type="radio"
                        name="verification_code_destination"
                        value="email"
                        onChange={handleUserInput}
                        id="myRadioId3"
                        className="radio__input mr-1"
                      />
                      <div className="radio__radio1"></div>
                      <h4 className="my-auto">Email</h4>
                    </label>
                  </div>
                  <div className="mt-4 continue-btn">
                    <button type="button"
                      className="btn btn-success btn-next"
                      onClick={handleValidation}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessDetails;
