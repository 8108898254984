import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
const PageHeader = () => {
	const navigate=useNavigate();

		const logout=()=>{
			axios.post('/prime.sika/v1/users/logout',{user_type:'merchant'})
			localStorage.removeItem('company_code');
			localStorage.removeItem('token');
			navigate('/')
		}
	const capitalize=(str)=>{
		return str?.charAt(0).toUpperCase() + str?.slice(1);
	}
	const [userDetails,setUserDetails]=useState([]);
	useEffect(()=>{
		axios.get('/prime.sika/v1/users/merchant/details').then(res=>{
			setUserDetails(res.data.data.user_details);
		  }).catch(err=>{
			console.log(err.response)
		  })
    },[])
        return (
			<>
			<div className="page-header">
			<div className="row justify-content-between">
				<div className="col-xl-5">
					<h6 className="text-secondary text-margin-top text-font mt-3">Welcome,</h6>
					<h3 className="text-font-1 company-name-margin">{capitalize(userDetails?.person?.companies[0]?.name)}</h3>
				</div>
				<div className="col-xl-3">
					<div className="form-group">
						<input className="form-control form-padding" type="text" placeholder="Search here..."/><img src="assets/img/search-normal.png" alt="" className="icon-position" />
					</div>
				</div>
				
				<div className="col-xl-3 top-content-mobile">
					<div className="row">
						<div className="col-xl-3">
							<div className="notification-icon-cover">
								<a href="#"><img src="/assets/img/notification.png" alt="" className="notification-icon-position" /></a>
							</div>
						</div>
						<div className="col-xl-9">
							<Dropdown>
								<Dropdown.Toggle variant="white"  className='d-flex admin_content' no-caret>
									<div className="image">
										<img src={userDetails?.person?.companies[0]?.logo_url ? userDetails?.person?.companies[0]?.logo_url :"/assets/img/default.png"} alt="" />
									</div>
									<div className="admin_name">
										<h4 className='my-auto mx-2' title={(userDetails?.person?.full_name)}>{(userDetails?.person?.full_name)}</h4>
									</div>
									<div className="admin_dropdownicon">
										<img src="assets/img/arrow-down.png" alt="" />
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item><button onClick={logout} className="logout-btn">Logout</button></Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
				
			</div>
			</div>
			</>
        )
    }
export default PageHeader;