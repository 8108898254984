import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import PagePagination from "../../components/PagePagination";
const RedemptionHistory = ({original, setCardRedemptionTable, setTotalPage, cal, setCal, isloading, cardRemptionTable, totalPage, setCurrentPage}) => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
 

  const handleChange = (e) => {
    setCal(e);
    var temp = original.filter((ele) => ele.created_at.includes(e));
    setCardRedemptionTable(temp);
  };

  const transform1 = (e) => {
    switch (e) {
      case "PAID":
        return <span class="btn btn-sm bg-success-light mr-2">Paid</span>;
        break;
      case "failed":
        return <span class="btn btn-sm bg-danger-light mr-2">Failed</span>;
        break;
      case "pending":
        return <span class="btn btn-sm bg-warning-light mr-2">Pending</span>;
        break;

      default:
        break;
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div class="card card-table card-table-height7">
          <div class="card-header card-header-margin">
            <div className="col-lg-12">
              <h3 class="my-auto">Redemption History</h3>
              <div className="d-flex justify-content-between mt-4 mb-2">
                <div>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="search customer"
                    onChange={(e) => setSearch(e.target.value)}
                  /> */}
                </div>
                <div className="form-group d-flex">
                  <label className="text-nowrap my-auto mr-2">
                    Filter by Month
                  </label>
                  <input
                    value={cal}
                    onChange={(e) => handleChange(e.target.value)}
                    className="form-control"
                    type="month"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body booking_card">
            <div class="table-responsive">
              <table
                id="transaction-table"
                class="datatable table table-stripped table table-hover table-center mb-0"
              >
                <thead>
                  <tr>
                    <th>Card ID</th>
                    <th>Customer</th>
                    <th>Telephone</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {isloading ? (
                    <>
                      {cardRemptionTable?.length === 0 ? (
                        <div className="error-alert-position1 mt-5">
                          <h1>No Records Found</h1>
                        </div>
                      ) : (
                        cardRemptionTable?.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{ele?.card?.id}</td>
                              <td>
                                {capitalize(
                                  ele?.card_account?.card_owner?.person
                                    ?.full_name
                                )
                                  ? ele?.card_account?.card_owner?.person
                                      ?.full_name
                                  : "N/A"}
                              </td>
                              <td>
                                {ele?.card_account?.card_owner?.phone_number
                                  ? ele?.card_account?.card_owner?.phone_number
                                  : "N/A"}
                              </td>
                              <td>¢{parseFloat(ele?.amount).toFixed(2)}</td>
                              <td>{moment(ele.created_at).format("lll")}</td>
                              <td>{transform1(ele.status)}</td>
                            </tr>
                          );
                        })
                      )}
                    </>
                  ) : (
                    <div className="error-alert-position1 mt-5">
                      <i className="fas fa-spinner fa-spin mr-2"></i>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mr-2 mb-2">
            <PagePagination
              setTotalPage={setTotalPage}
              totalPage={totalPage}
              setCurrentPage={(e) => setCurrentPage(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RedemptionHistory;
