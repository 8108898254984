// import PagePagination from "../components/PagePagination";
import React, { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import moment from "moment";
import PagePagination from "../../components/PagePagination";

const CardDisbursement = () => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  const [search, setSearch] = useState("");
  const [exportTable, setExportTable] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [original, setOriginal] = useState([]);
  const [cardDisbursementTable, setCardDisbursementTable] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const fileType = "xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(exportTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Card Disbursements"+ ".xlsx");
  };

  useEffect(() => {
    if (search === "") {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/disbursements?merchant_code=${localStorage.getItem(
            "company_code"
          )}&limit=10&page=${currentPage}`
        )
        .then((res) => {
          setLoading(true);
          setOriginal(res.data.data.disbursements);
          setCardDisbursementTable(res.data.data.disbursements);
        console.log("cons",res.data.data.disbursements);

          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/disbursements?merchant_code=${localStorage.getItem(
            "company_code"
          )}&limit=50&search_string=${search}`
        )
        .then((res) => {
          setLoading(true);
          setOriginal(res.data.data.disbursements);
          setCardDisbursementTable(res.data.data.disbursements);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [currentPage, search]);
  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/merchant-accounts/disbursements?merchant_code=${localStorage.getItem(
          "company_code"
        )}`
      )
      .then((res) => {
        setExportTable(res.data.data.disbursements);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [currentPage]);

  const filteredList = cardDisbursementTable?.filter((ele) => {
    if (search === "") {
      return ele;
    } else {
      if (ele?.related_transaction?.person_info?.full_name) {
        return ele;
      }
    }
  });

  const transform1 = (e) => {
    switch (e) {
      case "completed":
        return <span class="btn btn-sm bg-success-light mr-2">Completed</span>;
        break;
      case "failed":
        return <span class="btn btn-sm bg-danger-light mr-2">Failed</span>;
        break;
      case "pending":
        return <span class="btn btn-sm bg-warning-light mr-2">Pending</span>;
        break;

      default:
        break;
    }
  };
  return (
    <div className="card card-height">
      <div className="card-header cr-header card-header-margin d-flex justify-content-between">
        <div>
          <h3 className="my-auto">Card Disbursement</h3>
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="search customer"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {/* <div>
          <button className="btn btn-primary" onClick={exportToCSV}>
            Export as Excel
          </button>
        </div> */}
      </div>
      <div className="card-body booking_card">
        <div className="table-responsive">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Telephone</th>
                <th>Disbursed Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {isloading ? (
                <>
                  {cardDisbursementTable?.length === 0 ? (
                    <div className="error-alert-position1 mt-5">
                      <h1>No Records Found</h1>
                    </div>
                  ) : (
                    filteredList?.map((ele, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {capitalize(
                              ele?.related_transaction?.person_info?.full_name
                            )
                              ? ele?.related_transaction?.person_info?.full_name
                              : "N/A"}
                          </td>
                          <td>
                            {ele?.related_transaction?.person_info?.phone
                              ? ele?.related_transaction?.person_info?.phone
                              : "N/A"}
                          </td>
                          <td className="text-center">¢{parseFloat(ele?.payments[0]?.disbursement_amount).toFixed(2)}</td>
                          <td>{moment(ele?.payments[0]?.created_at).format("lll")}</td>
                          <td>{transform1(ele?.state)}</td>
                        </tr>
                      );
                    })
                  )}
                </>
              ) : (
                <div className="error-alert-position1 mt-5">
                  <i className="fas fa-spinner fa-spin mr-2"></i>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mr-2">
        <PagePagination
          setTotalPage={setTotalPage}
          totalPage={totalPage}
          setCurrentPage={(e) => setCurrentPage(e)}
        />
      </div>
    </div>
  );
};
export default CardDisbursement;
