
const LoginLayout = (props) => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <div className="left-div-content-1 mt-3">
            <img src="assets/img/login-bg.png" alt="" className="img-fluid" />
          </div>
          <div className="left-div-content-5">
            <h1>The <b>PrimePay App</b> allows you to</h1>
            <h4>redeem a customer's gift card by 
              entering the 16-digit code on the card,
              <span> and see how your business is doing on-the-go.</span>
              <span>Also have access to all of your sales activities.</span></h4>
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 pl-0">
          {props.children}
        </div>
      </div>

    </>
  )
}

export default LoginLayout