import Asaffiliate from './affiliateComponents/Asaffiliate';
import LoginCredentials from './affiliateComponents/LoginCredentials';
import ThankU from './affiliateComponents/ThankU';
import VerifyAccount from './affiliateComponents/VerifyAccount';
import { useState } from 'react';

const AffiliateSignUp = () => {
    const [screen,setScreen]=useState('affiliate1')

    var layout=<Asaffiliate setScreen={setScreen} screen={screen}/>
    if(screen==='affiliate1'){
        layout=<Asaffiliate setScreen={setScreen} next="loginCredentials"/>
    }
    else if(screen==='loginCredentials'){
        layout=<LoginCredentials setScreen={setScreen} next="verifyAccount"/>
    }
    else if(screen==='verifyAccount'){
        layout=<VerifyAccount setScreen={setScreen} next="thankU"/>
    }
    // else if(screen==='thankU'){
    //     layout=<ThankU setScreen={setScreen}/>
    // }
  return (
    <div id="form-step-div2">
        {layout}
    </div>
  );
};

export default AffiliateSignUp;
