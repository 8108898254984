import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Card from "../dashboardComponents/Card";
import Card1 from "../dashboardComponents/Card1";

const GeneralStats = () => {
  //

  const [stats, setStats] = useState([]);

  const loadStats = () => {
    axios
      .get("/prime.sika/v1/merchants/dashboard/general-stats")
      .then((res) => {
        if (res.data.status === 200) {
          setStats(res.data.data);
          setUsers(stats["new_users"]["count"]);
          setSales(stats["total_sales"]["count"]);
          setCardUse(stats["in_store_card_use"]["count"]);
          setCashedOut(stats["cashed_out"]["count"]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [new_users, setUsers] = useState(0);
  const [total_sales, setSales] = useState(0);
  const [in_store_card_use, setCardUse] = useState(0);
  const [cashed_out, setCashedOut] = useState(0);

  useEffect(() => {
    loadStats();
  }, []);

  const [sum, setSum] = useState(0);
  const [sum1, setSum1] = useState(0);
  const [original, setOriginal] = useState([]);
  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem("company_code")}`
      )
      .then((res) => {
        setOriginal(res.data.data.redemptions);
        var sumTemp = res.data.data.redemptions.reduce(
          (ps, a) => ps + parseFloat(a.amount),
          0
        );
        setSum(sumTemp);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);


  const [fund, setFund] = useState(0);
  var RedeemedCards = original?.length;
  useEffect(() => {
    axios
      .get("/prime.sika/v1/merchants/dashboard/available-funds")
      .then((res) => {
        if (res.data.status === 200) {
          setFund(res.data.data.available_funds);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <Card1
        image="/assets/img/card-tick.png"
        value={RedeemedCards}
        title="Redeemed Cards"
        subtitle="Redeemed Cards"
        background="card board1 fill5"
      />
      <Card
        image="/assets/img/dollar-circle.png"
        value={sum.toFixed(2)}
        title="Total Amount"
        subtitle="e-Gift Card"
        background="card board1 fill6"
      />
      <Card
        image="/assets/img/dollar-circle.png"
        value={fund.toFixed(2)}
        title="Available Funds"
        subtitle="e-Gift Card"
        background="card board1 fill8"
      />
    </>
  );
};

export default GeneralStats;
