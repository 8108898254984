const ThankYou = () => {
  return (
    <div className="">
      <div className="row justify-content-between">
        <div className="col-lg-5 col-12 left-div">
          <div className="left-div-padding">
            <div className="left-div-content">
              <h1 className="text-white">
                Appreciate your staff with<span>Prime e-gift card</span>
              </h1>
            </div>
            <div className="left-content">
              <h2 className="text-white">
                Don't know of which e-gift<span>card brand to give?</span>
              </h2>
            </div>
            <div className="left-div-cont-last">
              <h4 className="text-white">
                Prime e-gift card is an unristricted e-gift card
                <span>
                  that can be redeemed at any Prime participating merchant
                </span>
              </h4>
            </div>
            <div className="mt-5 ">
              <a href="#" className="btn btn-warning learn-more-btn">
                Learn more
              </a>
            </div>
          </div>
          <div className="back-img">
            <img src="assets/img/bg6.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0 right-div">
          <div className="card card-size1 mt-5 card-size-position">
            <div className="card-body">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12">
                  <div>
                    <h3>Thank You</h3>
                  </div>
                  <div className="d-flex line-grp">
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                    <div className="line active"></div>
                  </div>
                  <div className="font-enter">
                    <h4>
                      You are all set, to shop e-gift cards from brands you
                      trust.
                    </h4>
                  </div>
                  <div className="mt-4 continue-btn">
                    <button className="btn btn-success">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
