const LoginCredentials = ({setScreen,next}) => {
    return(
        <>
        <div className=""> 
            <div className="row justify-content-between">
            <div className="col-lg-5 col-12 left-div1">
                <div className="left-div-padding">

                    <div className="left-div-content">
                    <h1 className="text-white">E-gift card <span>Bulk purchase</span></h1>
                    </div>
                    <div className="left-content">
                    <h2 className="text-white">Made simple!</h2>
                    </div>
                    <div className="left-div-cont-last">
                        <ul className="text-white">
                            <li>Select e-gift cards of your choice,</li>
                            <li>Upload Excel sheet of recepients name and contacts</li>
                            <li>Add message and or repeat for all</li>
                        </ul>
                    </div>
                    <div className="mt-5">
                    <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                    </div>
                </div>
                <div className="back-img">
                    <img src="assets/img/bg6.png" alt="" />
                </div>
            </div>
            <div className="col-lg-6 col-12 pr-0 right-div">
                <div className="card card-size5 mt-5">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-flex float-right log-size">
                                <h4>Already registered?<a href="login.html" className="btn btn-outline-primary">Log In</a></h4>
                            </div>
                            <div className="mb-2">
                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev1" /></a>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div>
                                <h3>Log in Credentials</h3>
                            </div>
                            <div>
                                <h4>Enter your details below</h4>
                            </div>
                            <div className="d-flex line-grp">
                                <div className="line active1"></div>
                                <div className="line active1"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className="font-enter">
                                <h4>Last step, <span>Enter your login authentication details</span></h4>
                            </div>
                            <div className="form-group mt-1">
                                <input type="text" className="form-control textbx-size1" placeholder="Username" />
                            </div>
                            <div className="form-group mt-1">
                                <input type="password" className="form-control textbx-size1" placeholder="Password" />
                            </div>
                            <div className="form-group mt-1">
                                <input type="password" className="form-control textbx-size1" placeholder="confirm password" />
                            </div>
                            <div className="form-group mt-1">
                                <input type="text" className="form-control textbx-size1" placeholder="Enter four digit pin" />
                            </div>
                            <div className="form-group mt-1">
                                <input type="text" className="form-control textbx-size1" placeholder="confirm pin" />
                            </div>
                            <div className="d-flex  mt-3 check-text-size1">
                                <input type="checkbox" className="checkbox-design" id="yes" />
                                <label htmlFor="yes" className="label-bottom"><h4 className="ml-2">Yes, I'd like to be first to receive news and special offers from Prime+</h4></label>
                            </div>
                            <div className="d-flex check-text-size1">
                                <input type="checkbox" className="checkbox-design" id="continue" />
                                <label htmlFor="continue" className="label-bottom"><h4 className="ml-2">By continuing, you agree to Prime+'s <span>Privacy Policy</span> and <span>Terms of Service</span></h4></label>
                            </div>
                            <div className="mt-2 continue-btn">
                                <a className="btn btn-primary btn-next1" onClick={()=>setScreen('thankU')}>Submit</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
            </div>
            </div>
        </div>
        </>
    )
}
export default LoginCredentials