import "./App.css";
import Home from "./pages/Dashboard";
import CustomerCare from "./pages/CustomersCare";
import Settings from "./pages/Settings";
import { Route, Routes,useNavigate } from "react-router-dom";
import PrimepayLogin from "./pages/PrimepayLogin";
import Signup from "./pages/Signup";
import Primepay from "./pages/PrimePay";
import axios from "axios";
import { useEffect } from 'react';

function App() {
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Da-Secret"] = "pCweb";
  // axios.defaults.baseURL = `https://api.20pesewas.com`;
  axios.defaults.baseURL = `https://api.primeegiftcard.com`;
  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const navigate=useNavigate();
  const isLoggedIn=()=>{
    const token=localStorage.getItem('token');
    if(!token){
        navigate('/');
    }
  }

  useEffect(()=>{
    isLoggedIn();
  },[])


  return (
    <>
        <Routes>
          <Route path="/" element={<PrimepayLogin />}></Route>
          <Route exact path="/dashboard" element={<Home />} />
          <Route path="/primepay" element={<Primepay />} />
          <Route path="/customercare" element={<CustomerCare />} />
          <Route path="/signup" element={<Signup />}></Route>
        </Routes>
    </>
  );
}

export default App;
