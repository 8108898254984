import React from "react";
import { Link } from 'react-router-dom';
import { useState, useEffect} from 'react';
import MerchantSignUp from "./signupComponents/MerchantSignUp";
import AffiliateSignUp from "./signupComponents/AffiliateSignUp";
       
const Signup = () =>{
        const show = () => {
            if((document.getElementById("myRadioId")).checked){
                document.getElementById('form-step-div1').style.display = "block" ;
                document.getElementById('show-next').style.display = "none" ;
            }
            else if((document.getElementById("myRadioId1")).checked){
                document.getElementById('form-step-div2').style.display = "block" ;
                document.getElementById('show-next').style.display = "none" ;
            }
            else if((document.getElementById("myRadioId4")).checked){
                document.getElementById('form-step-div3').style.display = "block" ;
                document.getElementById('show-next').style.display = "none" ;
            }
            // else if((document.getElementById(myRadioId2)))
		}
        const show1 = () => {
            document.getElementById('form-step-div2').style.display = "none" ;
            document.getElementById('show-next').style.display = "block" ;
        }
        const show2 = () => {
            document.getElementById('form-step-div1').style.display = "none" ;
            document.getElementById('show-next').style.display = "block" ;
        }
        const show3 = () => {
            document.getElementById('form-step-div3').style.display = "none" ;
            document.getElementById('show-next').style.display = "block" ;
        }
       
        return (
            <>
                <div className="row bg-white">
                    <div className="col-12">
                        <div className="logo-margin">
                            <img src="assets/img/logo.png" alt="" className="img-fluid logo-img-size" />
                        </div>
                    </div>
                </div>
                <form action="">
                    <div id="show-next">
                        <div className="row">
                            <div className="col-lg-6 col-12 ">
                                <div className="left-div-content-1 left-div-img1 mt-3">
                                    <img src="assets/img/bg5.png" alt="" className="img-fluid" />
                                </div>
                                <div className="left-div-content-2 gift-color">
                                    <h1>The Prime gift Solution, <span>eGift Card</span></h1>
                                </div>
                                <div className="left-div-content-3">
                                    <h3>Sell your company branded e-gift card <span>and increase your revenue</span></h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pr-0">
                                <div className="card card-size2 mt-3">
                                    <div className="card-body card-size-body">
                                        <div>
                                            <h3>Sign up as</h3>
                                        </div>
                                        <div>
                                            <h4>Seleect one</h4>
                                        </div>
                                        <div className="radio-group">
                                            <label htmlFor="myRadioId" className="radio d-flex align-items-center">
                                                <input type="radio" name="signup" id="myRadioId" className="radio__input mr-2" />
                                                <div className="radio__radio"></div>
                                                <h4 className="my-auto">Merchant</h4>
                                            </label>
                                            <label htmlFor="myRadioId1" className="radio d-flex align-items-center mt-4">
                                                <input type="radio" name="signup" id="myRadioId1" className="radio__input mr-2" />
                                                <div className="radio__radio"></div>
                                                <h4 className="my-auto">Affiliate</h4>
                                            </label>
                                            <label htmlFor="myRadioId4" className="radio d-flex align-items-center mt-4">
                                                <input type="radio" name="signup" id="myRadioId4" className="radio__input mr-2" />
                                                <div className="radio__radio"></div>
                                                <h4 className="my-auto">Primepay</h4>
                                            </label>
                                        </div>
                                        <div className="cont-margin continue-btn">
                                            <input type="button" className="btn btn-success" onClick={show} value="Continue"/>
                                        </div>
                                    
                                        <div className="row content-items-last">
                                        <div className="col-12">
                                            <h4>Have an account?<Link to="/" className="text-success ml-1">Sign In</Link></h4>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="float-right d-flex mb-4 align-items-center justify-content-between foot-width mr-3">
                                    <a href="/signup"><img src="assets/img/linkedin.png" alt="" className="img-fluid" /></a>
                                    <a href="/signup"><img src="assets/img/twitter.png" alt="" className="img-fluid" /></a>
                                    <a href="/signup"><img src="assets/img/facebook.png" alt="" className="img-fluid" /></a>
                                    <a href="/signup"><img src="assets/img/instagram.png" alt="" className="img-fluid" /></a>
                                    <a href="/signup"><img src="assets/img/youtube.png" alt="" className="img-fluid" /></a>
                                    <h5>primeegiftcard</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                    <MerchantSignUp/>
                    <AffiliateSignUp/>
                    <div id="form-step-div3">
                        <div className="form-step2 form-step-active2">
                            <div className="row">
                                <div className="col-lg-6 col-12 ">
                                    <div className="left-div-content-6 left-div-img1 mt-3">
                                        <img src="assets/img/bg8.png" alt="" className="img-fluid" />
                                    </div>
                                    <div className="left-div-content-6 gift-color">
                                        <h1>Our company branded <span>eGift Card</span></h1>
                                    </div>
                                    <div className="left-div-content-3">
                                        <ul className="ul-style">
                                            <li>Increased new customer acquisition</li>
                                            <li>Improved our customer loyalty</li>
                                            <li>Improved employee engagement</li>
                                            <li>Increased sales</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 pr-0">
                                    <div className="card card-size3 mt-3">
                                        <div className="card-body card-size-body">
                                            <div className="mb-4">
                                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top" onClick={show3} /></a>
                                            </div>
                                            <div>
                                                <h3>Affiliate Code</h3>
                                            </div>
                                            <div>
                                                <h4>Enter Affiliate code below to start signup</h4>
                                            </div>
                                            <div className="form-group form-group-marg">
                                                <input type="password" className="form-control textbx-size" placeholder="Affiliate Code" />
                                                <div className="img-backg">
                                                    <img src="assets/img/two-icons.png" alt="" className="img-fluid img-pos" />
                                                </div>
                                            </div>
                                            <div className="cont-margin1 continue-btn1">
                                                <a className="btn btn-success btn-next2">Start</a>
                                            </div>
                                        
                                            <div className="row content-items-last1">
                                            <div className="col-12">
                                                <h4>Don't have an account?<a href="signup.html" className="text-success ml-1">Sign Up</a></h4>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="float-right d-flex mb-4 align-items-center justify-content-between foot-width mr-3">
                                        <a href="#"><img src="assets/img/linkedin.png" alt="" className="img-fluid" /></a>
                                        <a href="#"><img src="assets/img/twitter.png" alt="" className="img-fluid" /></a>
                                        <a href="#"><img src="assets/img/facebook.png" alt="" className="img-fluid" /></a>
                                        <a href="#"><img src="assets/img/instagram.png" alt="" className="img-fluid" /></a>
                                        <a href="#"><img src="assets/img/youtube.png" alt="" className="img-fluid" /></a>
                                        <h5>primeegiftcard</h5>
                                    </div>
                                </div>
                            </div>
            
                        </div>
                        <div className="form-step2">       
                        <div className="row justify-content-between">
                            <div className="col-lg-5 col-12 left-div">
                                <div className="left-div-content padding-div-top">
                                    <h1 className="text-white">Go digital with your <span>company branded</span>e-gift card</h1>
                                </div>
                                <div className="left-div-cont-last">
                                    <h4 className="text-white">Branded E-Gift cards are more than 
                                    <span>a great gift idea. They're powerful</span>
                                    business tools that can help your <span>company grow and increase your</span>
                                    audience's engagement with your <span>brand.</span>
                                </h4>
                                </div>
                                <div className="mt-5">
                                    <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                </div>
                                <div className="back-img">
                                    <img src="assets/img/bg6.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size1 mt-5">
                                        <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="d-flex float-right log-size">
                                                    <h4>Already registered?<a href="login.html" className="btn btn-outline-success">Log In</a></h4>
                                                </div>
                                                <div className="mb-4">
                                                    <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev2" /></a>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <h3>Sign up to Prime Merchant</h3>
                                                </div>
                                                <div>
                                                    <h4>Enter your details below</h4>
                                                </div>
                                                <div className="d-flex line-grp">
                                                    <div className="line active"></div>
                                                    <div className="line"></div>
                                                    <div className="line"></div>
                                                    <div className="line"></div>
                                                    <div className="line"></div>
                                                </div>
                                                <div className="font-enter">
                                                    <h4>Hello, <span>What's your business name?</span></h4>
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input type="text" className="form-control textbx-size1" placeholder="Enter your business name here" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input type="text" className="form-control textbx-size1" placeholder="Enter your location here" />
                                                    <img src="assets/img/location.png" alt="" className="img-fluid loc-img-pos" />
                                                </div>
                                                <div className="form-group mt-2">
                                                    <input type="text" className="form-control textbx-size1" placeholder="Enter your business Tin Number" />
                                                </div>
                                                <div className="form-group1 mt-2 d-flex justify-content-between">
                                                    <input type="text" className="form-control textbx-size2" placeholder="Enter your telephone here" />
                                                    <input type="text" className="form-control textbx-size2" placeholder="Enter your email here" />
                                                </div>
                                                <div className="font-enter1">
                                                    <h4>Where do you want to receive your verification code?</h4>
                                                </div>
                                                <div className="radio-group1 d-flex">
                                                    <label htmlFor="myRadioId2" className="radio1 d-flex align-items-center">
                                                        <input type="radio" name="radio-phone" id="myRadioId2" className="radio__input mr-1" />
                                                        <div className="radio__radio1"></div>
                                                        <h4 className="my-auto">Phone</h4>
                                                    </label>
                                                    <label htmlFor="myRadioId3" className="radio1 d-flex align-items-center ml-4">
                                                        <input type="radio" name="radio-phone" id="myRadioId3" className="radio__input mr-1" />
                                                        <div className="radio__radio1"></div>
                                                        <h4 className="my-auto">Email</h4>
                                                    </label>
                                                </div>
                                                <div className="mt-4 continue-btn">
                                                    <a className="btn btn-success btn-next2">Continue</a>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-step2"> 
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-12 left-div">
                                    <div className="left-div-padding">
            
                                        <div className="left-div-content">
                                            <h1 className="text-white">Merchant Dashboard</h1>
                                        </div>
                                        <div className="left-content">
                                            <h2 className="text-white">Easy to Navigate</h2>
                                        </div>
                                        <div className="left-div-cont-last">
                                            <h4 className="text-white">Here's where your business has access to all the
                                            <span>tools you'll need to create, sell, track and</span>
                                            promote your eGift cards.</h4>
                                        </div>
                                        <div className="mt-5">
                                            <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                        </div>
                                    </div>
                                    <div className="back-img">
                                        <img src="assets/img/bg6.png" alt="" />
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size1 mt-5">
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex float-right log-size">
                                                <h4>Already registered?<a href="login.html" className="btn btn-outline-success">Log In</a></h4>
                                            </div>
                                            <div className="mb-4">
                                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev2" /></a>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <h3>Sign up to Prime Merchant</h3>
                                            </div>
                                            <div>
                                                <h4>Enter your details below</h4>
                                            </div>
                                            <div className="d-flex line-grp">
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line"></div>
                                                <div className="line"></div>
                                                <div className="line"></div>
                                            </div>
                                            <div className="font-enter">
                                                <h4>Brief business description</h4>
                                            </div>
                                            <div className="form-group mt-4">
                                                <textarea name="" id="" className="textarea-size1" placeholder="Enter brief description about the business and your services, this will be visible to customers on Prime e-gift app, Marketplace and Live Shop"></textarea>
                                            </div>
                                            
                                            <div className="d-flex  mt-3 check-text-size">
                                                <input type="checkbox" className="checkbox-design" id="single" />
                                                <label htmlFor="single"><h4 className="ml-2"><span>Single Merchant</span> (Managing single business location)</h4></label>
                                            </div>
                                            <div className="d-flex  mt-2 check-text-size">
                                                <input type="checkbox" className="checkbox-design" id="franchise" />
                                                <label htmlFor="franchise"><h4 className="ml-2"><span>Franchise Merchant</span> (Managing multiple business locations)</h4></label>
                                            </div>
                                            <div className="mt-2 continue-btn">
                                                <a className="btn btn-success btn-next2">Continue</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-step2"> 
                            <div className="row justify-content-between">
                            <div className="col-lg-5 col-12 left-div">
                                <div className="left-div-padding">
            
                                    <div className="left-div-content">
                                        <h1 className="text-white">Prime Pay App</h1>
                                    </div>
                                    <div className="left-content">
                                        <h2 className="text-white">Made Simple!</h2>
                                    </div>
                                    <div className="left-div-cont-last">
                                        <h4 className="text-white">Download the Prime Pay app to quickly scan & redeem gift
                                        <span>cards with the QR Code Scanner</span>
                                        See how your business is doing on-the-go.
                                        <span>Access to all of your sales activity</span></h4>
                                    </div>
                                    <div className="mt-5">
                                        <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                    </div>
                                </div>
                                <div className="back-img">
                                    <img src="assets/img/bg6.png" alt="" />
                                </div>
                                
                            </div>
                            <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size1 mt-5">
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex float-right log-size">
                                                <h4>Already registered?<a href="login.html" className="btn btn-outline-success">Log In</a></h4>
                                            </div>
                                            <div className="mb-4">
                                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev2" /></a>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <h3>Account Manager</h3>
                                            </div>
                                            <div>
                                                <h4>Enter your details below</h4>
                                            </div>
                                            <div className="d-flex line-grp">
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line"></div>
                                                <div className="line"></div>
                                            </div>
                                            <div className="font-enter">
                                                <h4>Hi, <span>Just want to know you</span></h4>
                                            </div>
                                            <div className="form-group mt-4">
                                                <input type="text" className="form-control textbx-size1" placeholder="Enter your full name here" />
                                            </div>
                                            <div className="form-group mt-4">
                                                <input type="text" className="form-control textbx-size1" placeholder="Enter your position here" />
                                            </div>
                                            <div className="mt-4 continue-btn">
                                                <a className="btn btn-success btn-next2">Continue</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-step2"> 
                            <div className="row justify-content-between">
                            <div className="col-lg-5 col-12 left-div">
                                <div className="left-div-padding">
            
                                    <div className="left-div-content">
                                        <h1 className="text-white">Prime Marketplace</h1>
                                    </div>
                                    <div className="left-content">
                                        <h2 className="text-white">Sell more products</h2>
                                    </div>
                                    <div className="left-div-cont-last">
                                        <h4 className="text-white">Subcribe to our online marketplace, list your product or
                                        <span>services for e-gift card holders to instantly purchase your</span>
                                        products or book appointment to patronize your
                                        <span>services</span></h4>
                                    </div>
                                    <div className="mt-5">
                                        <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                    </div>
                                </div>
                                <div className="back-img">
                                    <img src="assets/img/bg6.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size5 mt-5">
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex float-right log-size">
                                                <h4>Already registered?<a href="login.html" className="btn btn-outline-success">Log In</a></h4>
                                            </div>
                                            <div className="mb-2">
                                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev2" /></a>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <h3>Log in Credentials</h3>
                                            </div>
                                            <div>
                                                <h4>Enter your details below</h4>
                                            </div>
                                            <div className="d-flex line-grp">
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line"></div>
                                            </div>
                                            <div className="font-enter">
                                                <h4>Last step, <span>Enter your login authentication details</span></h4>
                                            </div>
                                            <div className="form-group mt-1">
                                                <input type="text" className="form-control textbx-size1" placeholder="Username" />
                                            </div>
                                            <div className="form-group mt-1">
                                                <input type="password" className="form-control textbx-size1" placeholder="Password" />
                                            </div>
                                            <div className="form-group mt-1">
                                                <input type="password" className="form-control textbx-size1" placeholder="confirm password" />
                                            </div>
                                            <div className="form-group mt-1">
                                                <input type="text" className="form-control textbx-size1" placeholder="Enter four digit pin" />
                                            </div>
                                            <div className="form-group mt-1">
                                                <input type="text" className="form-control textbx-size1" placeholder="confirm pin" />
                                            </div>
                                            <div className="d-flex  mt-3 check-text-size1">
                                                <input type="checkbox" className="checkbox-design" id="yes" />
                                                <label htmlFor="yes" className="label-bottom"><h4 className="ml-2">Yes, I'd like to be first to receive news and special offers from Prime+</h4></label>
                                            </div>
                                            <div className="d-flex check-text-size1">
                                                <input type="checkbox" className="checkbox-design" id="continue" />
                                                <label htmlFor="continue" className="label-bottom"><h4 className="ml-2">By continuing, you agree to Prime+'s <span>Privacy Policy</span> and <span>Terms of Service</span></h4></label>
                                            </div>
                                            <div className="mt-2 continue-btn">
                                                <a className="btn btn-success btn-next2">Submit</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                            </div>
                            </div>
                        </div>
                        <div className="form-step2"> 
                            <div className="row justify-content-between">
                            <div className="col-lg-5 col-12 left-div">
                                <div className="left-div-padding">
            
                                    <div className="left-div-content">
                                        <h1 className="text-white">Promote Sale</h1>
                                    </div>
                                    <div className="left-content">
                                        <h2 className="text-white">On your website or social media</h2>
                                    </div>
                                    <div className="left-div-cont-last">
                                        <h4 className="text-white">Launch a digital gifting solution on your website without
                                        <span>complex integrations, Copy and paste Prime widget URL</span>
                                        on your website to promote sales</h4>
                                    </div>
                                    <div className="left-div-cont-last">
                                        <h4 className="text-white">Share your gift card on your social media platforms to
                                        <span>increase sales</span></h4>
                                    </div>
                                    <div className="mt-5">
                                        <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                    </div>
                                </div>
                                <div className="back-img">
                                    <img src="assets/img/bg6.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size1 mt-5">
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="d-flex float-right log-size">
                                                <h4>Already registered?<a href="login.html" className="btn btn-outline-success">Log In</a></h4>
                                            </div>
                                            <div className="mb-4">
                                                <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top btn-prev2" /></a>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <h3>Verify Account</h3>
                                            </div>
                                            <div>
                                                <h4>Enter your details here</h4>
                                            </div>
                                            <div className="d-flex line-grp">
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                            </div>
                                            <div className="font-enter">
                                                <h4>Last step, <span>Enter the six digit code sent you</span></h4>
                                            </div>
                                            <div className="form-group mt-4">
                                                <input type="number" className="form-control textbx-size1" placeholder="Six digit code" />
                                            </div>
                                            <div className="mt-4 continue-btn">
                                                <a className="btn btn-success btn-next2">Verify</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-step2"> 
                            <div className="row justify-content-between">
                                <div className="col-lg-5 col-12 left-div">
                                <div className="left-div-padding">
            
                                    <div className="left-div-content">
                                        <h1 className="text-white">Appreciate your staff with<span>Prime e-gift card</span></h1>
                                    </div>
                                    <div className="left-content">
                                        <h2 className="text-white">Don't know of which e-gift<span>card brand to give?</span></h2>
                                    </div>
                                    <div className="left-div-cont-last">
                                        <h4 className="text-white">Prime e-gift card is an unristricted e-gift card<span>that can be redeemed at any Prime participating merchant</span></h4>
                                    </div>
                                    <div className="mt-5 ">
                                    <a href="#" className="btn btn-warning learn-more-btn">Learn more</a>
                                    </div>
                                </div>
                                <div className="back-img">
                                    <img src="assets/img/bg6.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 pr-0 right-div">
                                <div className="card card-size1 mt-5 card-size-position">
                                    <div className="card-body">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-lg-12">
                                            <div>
                                                <h3>Thank You</h3>
                                            </div>
                                            <div className="d-flex line-grp">
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                                <div className="line active"></div>
                                            </div>
                                            <div className="font-enter">
                                                <h4>You are all set, to shop e-gift cards from brands you trust.</h4>
                                            </div>
                                            <div className="mt-4 continue-btn">
                                                <a className="btn btn-success">Close</a>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
export default Signup;