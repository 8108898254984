import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpField from "react-otp-field";
import swal from "sweetalert";
const CodeModal = ({
  show,
  isloading,
  isloading1,
  errorMessage,
  resendVerification,
  handleClose,
  verifycode,
  setVerifyCode,
  handleValidation,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="ml-3">
            <h4>
              A 6-digit code has been sent to owner of this gift card, Kindly
              enter it below.
            </h4>
          </div>
          <OtpField
            value={verifycode}
            onChange={setVerifyCode}
            numInputs={6}
            onChangeRegex={/^([0-9]{0,})$/}
            autoFocus
            isTypeNumber
            inputProps={{
              className: "form-control number-textbox1 ml-3",
              disabled: false,
            }}
          />
          <small className="text-danger">{errorMessage}</small>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-7">
              {!isloading1 && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={resendVerification}
                >
                  Resend Code
                </button>
              )}
              {isloading1 && (
                <button className="btn btn-secondary" disabled>
                  <i className="fas fa-spinner fa-spin mr-2"></i>loading...
                </button>
              )}
            </div>

            <div className="col-lg-5">
              <div className="row justify-content-between">
                {!isloading && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleValidation}
                  >
                    Proceed
                  </button>
                )}
                {isloading && (
                  <button className="btn btn-success" disabled>
                    <i className="fas fa-spinner fa-spin mr-2"></i>loading...
                  </button>
                )}
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CodeModal;
