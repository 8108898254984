const Asaffiliate = ({setScreen,next}) => {
    return(
        <>
            <div className="">
                <div className="row">
                    <div className="col-lg-6 col-12 ">
                        <div className="left-div-content-6 left-div-img1 mt-3">
                            <img src="assets/img/bg10.png" alt="" className="img-fluid" />
                        </div>
                        <div className="left-div-content-6 boss">
                            <h1>Be your own <span>Boss</span></h1>
                        </div>
                        <div className="left-div-content-3">
                            <h3>Sign up merchants and earn commisssion <span>as customers buy their gift cards</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 pr-0">
                        <div className="card card-size4 mt-3">
                            <div className="card-body card-size-body">
                                <div className="mb-4">
                                    <a href="#"><img src="assets/img/arrow-left.png" alt="" className="arrow-margin-top"/></a>
                                </div>
                                <div>
                                    <h3>Sign up as Affiliate</h3>
                                </div>
                                <div>
                                    <h4>Enter Affiliate code below to start signup</h4>
                                </div>
                                <div className="d-flex line-grp">
                                    <div className="line active1"></div>
                                    <div className="line"></div>
                                    <div className="line"></div>
                                    <div className="line"></div>
                                </div>
                                <div className="form-group mt-5">
                                    <input type="password" className="form-control textbx-size" placeholder="Full Name" />
                                    <div className="img-backg1">
                                        <img src="assets/img/username.png" alt="" className="img-fluid img-pos" />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="password" className="form-control textbx-size" placeholder="Affiliate Code" />
                                    <div className="img-backg1">
                                        <img src="assets/img/two-icons1.png" alt="" className="img-fluid img-pos" />
                                    </div>
                                </div>
                                <div className="cont-margin1 continue-btn1">
                                    <button className="btn btn-primary btn-next1" onClick={()=>setScreen(next)}>Start</button>
                                </div>
                            
                                <div className="row content-items-last2">
                                <div className="col-12">
                                    <h4>Don't have an account?<a href="signup.html" className="text-primary ml-1">Sign Up</a></h4>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="float-right d-flex mb-4 align-items-center justify-content-between foot-width1 mr-3">
                            <a href="#"><img src="assets/img/linkedin1.png" alt="" className="img-fluid" /></a>
                            <a href="#"><img src="assets/img/twitter1.png" alt="" className="img-fluid" /></a>
                            <a href="#"><img src="assets/img/facebook1.png" alt="" className="img-fluid" /></a>
                            <a href="#"><img src="assets/img/instagram1.png" alt="" className="img-fluid" /></a>
                            <a href="#"><img src="assets/img/youtube1.png" alt="" className="img-fluid" /></a>
                            <h5>primeegiftcard</h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Asaffiliate