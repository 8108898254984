import React, { createRef, useEffect, useRef, useState } from "react";
import OtpField from "react-otp-field";

import swal from "sweetalert";
import axios from "axios";
import CodeModal from "./CodeModal";
const CardRedemption = ({
  verificationCode,
  setVerificationCode,
  setErrorMessage,
  errorMessage,
  amount,
  val,
  setVal,
  show,
  setShow,
  handleClose,
  verifycode,
  setVerifyCode,
  handleValidation,
}) => {
  const [isloading, setLoading] = useState(false);
  const [isloading1, setLoading1] = useState(false);
  const [count, setCount] = useState(0);

  const del = () => {
    setVal(val.slice(0, -1));
    if (val.includes(".") && !val.slice(0, -1).includes(".")) {
      setCount(count - 1);
    }
  };

  const amountTextbox = useRef("");
  const pinTextbox = useRef("");
  useEffect(() => {
    amountTextbox.current.focus();
  }, []);

  const handleCodeVerification = (e) => {
    e.preventDefault();
    setLoading(true);
    if (verificationCode.length !== 16) {
      setLoading(false);
      setErrorMessage("Invalid Verification Code Entered");
    } else if (val === "") {
      setLoading(false);
      swal({
        title: "Error",
        text: "Enter Amount",
        icon: "error",
        button: "OK",
      });
    } else if (!amount) {
      setLoading(false);
      swal({
        title: "Error",
        text: "Invalid Amount",
        icon: "error",
        button: "OK",
      });
    } else {
      axios
        .get(`prime.sika/v1/accounts/merchant/${verificationCode}`)
        .then((res) => {
          if (res?.data?.status === 200) {
            axios
              .post("prime.sika/v1/accounts/merchant/send-confirmation-code", {
                account_code: verificationCode,
              })
              .then((res) => {
                setShow(true);
                setLoading(false);
              })
              .catch((err) => {
                console.log(err.response);
                swal({
                  title: "Error",
                  text: err.response.data.error_msg,
                  icon: "error",
                  button: "OK",
                  confirmButtonColor: "#00b17d",
                });
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response.data);
          if (err.response.data.status === 400) {
            swal({
              title: "Error",
              text: err.response.data.error_msg,
              icon: "error",
              button: "OK",
            });
            setLoading(false);
          }
        });
    }
  };
  const onClickAmount = () => {
    amountTextbox.current.focus();
  };

  const onClickPin = () => {
    if (pinTextbox.current.onClick()) {
      pinTextbox.current.focus();
      pinTextbox.value = val;
      amountTextbox.value = "";
    }
  };

  const resendVerification = () => {
    setLoading1(true);
    axios
      .post("prime.sika/v1/accounts/merchant/send-confirmation-code", {
        account_code: verificationCode,
      })
      .then((res) => {
        setShow(true);
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading1(false);
        swal({
          title: "Error",
          text: err.response.data.error_msg,
          icon: "error",
          button: "OK",
          confirmButtonColor: "#00b17d",
        });
      });
  };
  const increment = () => {
    setCount(count + 1);
  };

  return (
    <div className="card card-table card-table-height6">
      <div className="card-body booking_card">
        <form onSubmit={handleCodeVerification}>
          <div className="row justify-content-between">
            <div className="col-lg-7">
              <div className="row">
                <div className="ml-2">
                  <h4>Amount in Ghana Cedis</h4>
                </div>
              </div>
              <div className="row">
                <div>
                  <div className="form-group d-flex">
                    <input
                      className="form-control number-textbox ml-2"
                      type="text"
                      ref={amountTextbox}
                      value={val}
                      onChange={(e) => setVal(e.target.value)}
                      onClick={onClickAmount}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div>
                  <h4 className="ml-2">Enter 16 digit egift card number</h4>
                </div>
              </div>
              <div className="row mt-2">
                <div>
                  <div className="d-flex -justify-content-between">
                    <OtpField
                      ref={pinTextbox}
                      onClick={onClickPin}
                      value={verificationCode}
                      onChange={setVerificationCode}
                      numInputs={16}
                      onChangeRegex={/^([0-9]{0,})$/}
                      isTypeNumber
                      inputProps={{
                        className: "form-control number-textbox1 ml-2 mt-2",
                        disabled: false,
                      }}
                    />
                  </div>
                  <small className="text-danger ml-2">{errorMessage}</small>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-table calculator float-right">
                <div className="card-body booking_card d-block">
                  <div className="d-flex justify-content-between mt-3">
                    <input
                      type="button"
                      value="1"
                      className="bton"
                      onClick={(e) => {
                        setVal(val + e.target.value);
                      }}
                    />
                    <input
                      type="button"
                      value="2"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="3"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <input
                      type="button"
                      value="4"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="5"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="6"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <input
                      type="button"
                      value="7"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="8"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="9"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <input
                      type="button"
                      value="."
                      disabled={count === 1}
                      className="bton"
                      onClick={(e) => {
                        setVal(val + e.target.value);
                        increment();
                      }}
                    />
                    <input
                      type="button"
                      value="0"
                      className="bton"
                      onClick={(e) => setVal(val + e.target.value)}
                    />
                    <input
                      type="button"
                      value="<"
                      className="bton"
                      onClick={() => del()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="ml-2 d-flex justify-content-between">
              {!isloading && (
                <button type="submit" className="btn btn-success redeem-btn">
                  Redeem Card
                </button>
              )}
              {isloading && (
                <button className="btn btn-success redeem-btn" disabled>
                  <i className="fas fa-spinner fa-spin mr-2"></i>loading...
                </button>
              )}
              {/* <button type="button" className="btn btn-primary generate-qr-btn ml-1">Generate QR Code</button> */}
            </div>
          </div>
          <CodeModal
            show={show}
            setShow={setShow}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            isloading={isloading}
            isloading1={isloading1}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            resendVerification={resendVerification}
            amount={amount}
            setLoading={setLoading}
            handleClose={handleClose}
            verifycode={verifycode}
            setVerifyCode={setVerifyCode}
            handleValidation={handleValidation}
          />
        </form>
      </div>
    </div>
  );
};
export default CardRedemption;
