

const AffiliateForm = ({setScreen,next,userInput,handleUserInput}) => {
  const show2 = () => {
    document.getElementById('form-step-div1').style.display = "none" ;
    document.getElementById('show-next').style.display = "block" ;
}
  return (
    <div className="">
      <div className="row">
        <div className="col-lg-6 col-12 ">
          <div className="left-div-content-6 left-div-img1 mt-3">
            <img src="assets/img/bg8.png" alt="" className="img-fluid" />
          </div>
          <div className="left-div-content-6 gift-color">
            <h1>
              Our company branded <span>eGift Card</span>
            </h1>
          </div>
          <div className="left-div-content-3">
            <ul className="ul-style">
              <li>Increased new customer acquisition</li>
              <li>Improved our customer loyalty</li>
              <li>Improved employee engagement</li>
              <li>Increased sales</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-12 pr-0">
          <div className="card card-size3 mt-3">
            <div className="card-body card-size-body">
              <div className="mb-4">
                  <img
                    src="assets/img/arrow-left.png"
                    alt=""
                    className="arrow-margin-top"
                      onClick={show2}
                  />
              </div>
              <div>
                <h3>Affiliate Code</h3>
              </div>
              <div>
                <h4>Enter Affiliate code below to start signup</h4>
              </div>
              <div className="form-group form-group-marg">
                <input
                  type="text"
                  className="form-control textbx-size"
                  placeholder="Affiliate Code"
                  value={userInput.affiliate_code}
                  name="affiliate_code"
                  onChange={handleUserInput}
                />
                <div className="img-backg">
                  <img
                    src="assets/img/two-icons.png"
                    alt=""
                    className="img-fluid img-pos"
                  />
                </div>
              </div>
              <div className="cont-margin1 continue-btn1">
                <button className="btn btn-success btn-next" onClick={()=>setScreen('businessDetails')}>Start</button>
              </div>

              <div className="row content-items-last1">
                <div className="col-12">
                  <h4>
                    Don't have an account?
                    <a href="signup.html" className="text-success ml-1">
                      Sign Up
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="float-right d-flex mb-4 align-items-center justify-content-between foot-width mr-3">
            <a href="#">
              <img src="assets/img/linkedin.png" alt="" className="img-fluid" />
            </a>
            <a href="#">
              <img src="assets/img/twitter.png" alt="" className="img-fluid" />
            </a>
            <a href="#">
              <img src="assets/img/facebook.png" alt="" className="img-fluid" />
            </a>
            <a href="#">
              <img src="assets/img/instagram.png" alt="" className="img-fluid" />
            </a>
            <a href="#">
              <img src="assets/img/youtube.png" alt="" className="img-fluid" />
            </a>
            <h5>primeegiftcard</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateForm;
