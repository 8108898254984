import axios from 'axios';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
const Sidebar = () => {
	const navigate = useNavigate();

	const logout = () => {
		axios.post('/prime.sika/v1/users/logout', { user_type: 'merchant' })
		localStorage.removeItem('company_code');
		localStorage.removeItem('token');
		navigate('/')
	}
	return (
		<div className="sidebar" id="sidebar">

			<div className="sidebar-inner slimscroll">
				<div className="row align-content-center justify-content-center p-4">
					<div className="col-10">
						<div className="logo-position">
							<img src="/assets/img/prime_pay.png" width="60%" alt="logo" />
						</div>
					</div>
				</div><hr />

				<div id="sidebar-menu" className="sidebar-menu">
					<ul>
						<li className='active'> <NavLink to="/dashboard"><img src="assets/img/category.png" alt="" /> <span>Dashboard</span></NavLink> </li>
						<li> <NavLink to="/primepay"><img src="assets/img/cards.png" alt="" /> <span>Redeem Gift Card</span></NavLink> </li>
						<li> <NavLink to="/customercare"><img src="assets/img/customer-care.png" alt="" /> <span>Customer Care</span></NavLink> </li>
						<li><button onClick={logout} className="log-out-btn btn d-flex"><img src="assets/img/logout.png" alt="" className='mr-2'/> <span>Log Out</span></button></li><br />
					</ul>

				</div>
			</div>
		</div>
	)
}
export default Sidebar;