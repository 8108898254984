import AccountManager from "./merchantComponents/AccountManager";
import AffiliateForm from "./merchantComponents/AffiliateForm";
import BusinessDescription from "./merchantComponents/BusinessDescription";
import BusinessDetails from "./merchantComponents/BusinessDetails";
import Credentials from "./merchantComponents/Credentials";
import ThankYou from "./merchantComponents/ThankYou";
import Verification from "./merchantComponents/Verification";
import { useState } from 'react';
import axios from 'axios'

const MerchantSignUp = () => {
    const [screen,setScreen]=useState('affiliate')
    const [userInput,setUserInput]=useState({
        affiliate_code:"",
        business_name:"",
        location:"",
        tin_number:"",
        telephone:"",
        email:"",
        business_description: "",
        position:"",
        fullname:"",
        username:"",
        password:"",
        password_confirm:"",
        pin:"",
        pin_confirm:"",
        merchant_type:"single",
        verification_code_destination:"phone",
        policy:"",
        special_offer:"",
        gender:""

    })

    const saveData=()=>{
        const data={
            agent_ref:userInput.affiliate_code,
            username:userInput.username,
            password:userInput.password,
            confirmed_password:userInput.password_confirm,
            pin:userInput.pin,
            confirmed_pin:userInput.pin_confirm,
            telephone:userInput.telephone,
            email:userInput.email,
            firstname:userInput.fullname,
            address:userInput.location,
            gender:userInput.gender,
            verify_option:userInput.verification_code_destination,
            position:userInput.position,
            description:userInput.business_description,
            company:{
                name:userInput.business_name,
                business_registration_number:userInput.tin_number,
                tin_number:userInput.tin_number,
                type:userInput.merchant_type
            }
        }
        console.log(data);
        axios.post('/prime.sika/v1/users/merchant/signup',data).then((res)=>{
            console.log(res);
        }).catch(err=>{
            console.log(err.response)
        })
    }

    const handleUserInput=(e)=>{
        setUserInput((userInput)=>({
            ...userInput,[e.target.name]:e.target.value
        }))
    }
    
    var layout=<AffiliateForm setScreen={setScreen} screen={screen} userInput={userInput} handleUserInput={handleUserInput}/>
    if(screen==='affiliate'){
        layout=<AffiliateForm setScreen={setScreen} next="businessDetails" userInput={userInput} handleUserInput={handleUserInput}/>
    }
    else if(screen==='businessDetails'){
        layout=<BusinessDetails setScreen={setScreen} next="businessDescription" userInput={userInput} handleUserInput={handleUserInput}/>
    }else if(screen==='businessDescription'){
        layout=<BusinessDescription setScreen={setScreen} next="accountManager" userInput={userInput} handleUserInput={handleUserInput}/>
    }else if(screen==='accountManager'){
        layout=<AccountManager setScreen={setScreen} next="credentials" userInput={userInput} handleUserInput={handleUserInput}/>
    }else if(screen==='credentials'){
        layout=<Credentials setScreen={setScreen} next="verification" userInput={userInput} handleUserInput={handleUserInput} saveData={saveData}/>
    }else if(screen==='verification'){
        layout=<Verification setScreen={setScreen} next="thankYou"/>
    }else if(screen==='thankYou'){
        layout=<ThankYou setScreen={setScreen}/>
    }
  return (
    <div id="form-step-div1">
        {layout}
    </div>
  );
};

export default MerchantSignUp;
