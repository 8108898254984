import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import GeneralStats from "./dashboardComponents/GeneralStats";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import PagePagination from "../components/PagePagination";
import CardDisbursement from "./dashboardComponents/CardDisbursement";

const Dashboard = () => {
  const capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  const [cardRemptionTable, setCardRedemptionTable] = useState([]);
  const [original, setOriginal] = useState([]);
  const [exportTable, setExportTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    if (search === "") {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
            "company_code"
          )}&limit=10&page=${currentPage}`
        )
        .then((res) => {
          setLoading(true)
          setOriginal(res.data.data.redemptions);
          setCardRedemptionTable(res.data.data.redemptions);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      axios
        .get(
          `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
            "company_code"
          )}&limit=50&search_string=${search}`
        )
        .then((res) => {
          setLoading(true)
          setOriginal(res.data.data.redemptions);
          setCardRedemptionTable(res.data.data.redemptions);
          setTotalPage(res.data.data.total_pages);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [currentPage, search]);
  useEffect(() => {
    axios
      .get(
        `/prime.sika/v1/merchant-accounts/redemptions?merchant_code=${localStorage.getItem(
          "company_code"
        )}`
      )
      .then((res) => {
        setExportTable(res.data.data.redemptions);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [currentPage]);

  const filteredList = cardRemptionTable?.filter((ele) => {
    if (search === "") {
      return ele;
    } else {
      if (ele?.card_account?.card_owner?.person?.full_name) {
        return ele;
      }
    }
  });

  const transform1 = (e) => {
    switch (e) {
      case "PAID":
        return <span class="btn btn-sm bg-success-light mr-2">Paid</span>;
        break;
      case "failed":
        return <span class="btn btn-sm bg-danger-light mr-2">Failed</span>;
        break;
      case "pending":
        return <span class="btn btn-sm bg-warning-light mr-2">Pending</span>;
        break;

      default:
        break;
    }
  };
  const [query, setQuery] = useState("");
  const fileType = "xlsx";
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(exportTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Card Redemptions" + ".xlsx");
  };
  return (
    <DashboardLayout>
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <GeneralStats />

            <div className="col-lg-12">
              <CardDisbursement/>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="justify-content-center">
                <img
                  src="assets/img/gift.png"
                  alt=""
                  className="img-fluid gift-image-size2 picture-frame"
                />
                <div className="text-position1">
                  <h3 className="text-white">
                    Make your loved <span>one's happy</span>
                  </h3>
                </div>
                <div>
                  <a
                    href="https://primeegiftcard.com"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-secondary shop-btn-position1"
                  >
                    Shop eGift Card
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
              <div className="card card-height">
                <div className="card-header cr-header card-header-margin d-flex justify-content-between">
                  <div>
                    <h3 className="my-auto">Card Redemption</h3>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="search customer"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div>
                    <button className="btn btn-primary" onClick={exportToCSV}>
                      Export as Excel
                    </button>
                  </div>
                </div>
                <div className="card-body booking_card">
                  <div className="table-responsive">
                    <table className="datatable table table-stripped table table-hover table-center mb-0">
                      <thead>
                        <tr>
                          <th>Card ID</th>
                          <th>Customer</th>
                          <th>Telephone</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isloading ? (
                          <>
                            {cardRemptionTable?.length === 0 ? (
                              <div className="error-alert-position1 mt-5">
                                <h1>No Records Found</h1>
                              </div>
                            ) : (
                              filteredList?.map((ele, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{ele?.card?.id}</td>
                                    <td>
                                      {capitalize(
                                        ele?.card_account?.card_owner?.person
                                          ?.full_name
                                      )
                                        ? ele?.card_account?.card_owner?.person
                                            ?.full_name
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {ele?.card_account?.card_owner
                                        ?.phone_number
                                        ? ele?.card_account?.card_owner
                                            ?.phone_number
                                        : "N/A"}
                                    </td>
                                    <td>
                                      ¢{parseFloat(ele?.amount).toFixed(2)}
                                    </td>
                                    <td>
                                      {moment(ele.created_at).format("ll")}
                                    </td>
                                    <td>{transform1(ele.status)}</td>
                                  </tr>
                                );
                              })
                            )}
                          </>
                        ) : (
                          <div className="error-alert-position1 mt-5">
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mr-2">
                  <PagePagination
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    setCurrentPage={(e) => setCurrentPage(e)}
                  />
                </div>
              </div>
            </div>
      </div>
    </DashboardLayout>
  );
};
export default Dashboard;
